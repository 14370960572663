export function setSessionStorage(key, val) {
    sessionStorage.setItem(key, val);
}

export function getSessionStorage(key) {
    return sessionStorage.getItem(key);
}

export function setLocalStorage(key, val) {
    localStorage.setItem(key, val)
}

export function getLocalStorage(key) {
    localStorage.getItem(key)
}

export function getCurrentTime() {
    let currentTime = new Date().toUTCString();
    return currentTime
}

export function getQueryString(str) {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(str);
}
export function getRegionFromQS() {
    const queryParams = new URLSearchParams(window.location.search);
    var dataArr = [];
    if(queryParams.get('region')){
        dataArr['type'] = 'region';
        dataArr['data'] = queryParams.get('region');
        
    }else if(queryParams.get('organisation')){
        dataArr['type'] = 'organisation';
        dataArr['data'] = queryParams.get('organisation');   
    }

    return dataArr;
}

//for analytics tracking
export function addWidgetUrlParams(urlString, params) {
    // export const addUrlParams = (urlString, params) => {
    const searchParams = new URLSearchParams();
    searchParams.append("ps-preview",params.ps_preview);
    searchParams.append("utm_source",params.utm_source);
    searchParams.append("utm_medium",params.utm_medium);
    searchParams.append("utm_campaign",params.utm_campaign);
    return (urlString+'?'+searchParams.toString());
};

export function randomize(elements) {
    for (var i = elements.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = elements[i];
        elements[i] = elements[j];
        elements[j] = temp;
    }

    return elements;
}