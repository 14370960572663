import { Carousel } from 'react-responsive-carousel';
import React, { CSSProperties, useContext } from 'react';
import { carouselConfig, env, staticContent } from '../../constants/constants';
import '../../styles/RegionalWidget2.css';
import { withEventTracker, EventTrackerContext } from '@neighbourlynz/nbly-property-events';
import { addWidgetUrlParams } from '../../utils/utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const PREFIX_URL = env.CDN_URL;
const CDN_URL = env.CDN_URL;
const avatar = `${CDN_URL}bundles/neighbourlyregistration/img/avatar.png`;


const RWTemplate2 = (props) => {
    let data = props.data;
    const eventTracker = useContext(EventTrackerContext);
    const arrowStyles: CSSProperties = {
        position: 'fixed',
        zIndex: 2,
        top: '270px',
        width: 20,
        height: 60,
        fontSize: '2em',
        cursor: 'pointer',
        fontWeight: 10,
        paddingBottom: '50px',
    };
    let alreadyTrackedId = [];
    let UrlParms = {
        ps_preview: true,
        utm_source: 'stuff',
        utm_medium: 'carousel',
        utm_campaign: 'regional',
    };
    const createCarouselItemImage = (listing, index) => (

        <React.Fragment key={index} >
            <div lsid={listing.listingId} onClick={() => Redirect(addWidgetUrlParams(listing.realestateOriginLink, UrlParms), listing.listingId, eventTracker)}>
                <div onLoad={_initialTracking(listing.listingId, eventTracker, index, alreadyTrackedId)} >
                    
                    {/* <div className='float-start me-auto'>
                        <img src={process.env.PUBLIC_URL + '/regional-widget-assets/propertyBrokers/attachments/b776ba1692528b.18568411@2x.png'} className="brand-sub-logo_2 ms-0 ps-0" alt='' />
                    </div>
                    <div className='float-end'>
                        <img src={process.env.PUBLIC_URL + '/regional-widget-assets/propertyBrokers/attachments/649f4b80d7d82a.30630578@2x.png'} className="brand-logo_2" alt='' />
                    </div> */}

                </div>
                <div className='carousel-image-block'>
                    <LazyLoadImage className="carouselImage_2" src={`${PREFIX_URL}images/cache/800_low_quality/realestate_listing_images/${(listing.realestateListingImages && listing.realestateListingImages.length > 0) && listing.realestateListingImages[0].path}`} alt="" loading="lazy"  />
                </div>
                <div className="bg_color_container_2">
                    <div className='ImageAddress_2  ms-4 pl-1 pe-1 rw-mt-1'>
                        <div className='listing_subject_2 fw-bold FSAlbert-Bold rw-mt-5'>{listing.subject}</div>
                        <div className="property_address_2 FSAlbert-Regular rw-mt-5">{listing.realestateListingAddress.streetNumber} {listing.realestateListingAddress.street},{" "}
                            {listing.realestateListingAddress.suburb}
                            {_getRegion(listing.realestateListingAddress.region)}
                            {/* {listing.realestateListingAddress.region  && ','+ listing.realestateListingAddress.region} */}
                        </div>
                    </div>
                    <div className='Icons_2 rw-mt-5 ms-4 rw-mb-1 mt-0'>
                        <div>{listing.bedroomCount > 0 && <><div className='d-inline-flex align-items-center pe-2'><span className="pt-2 pe-1 fw-bold">{listing.bedroomCount}</span><span><LazyLoadImage src={process.env.PUBLIC_URL + '/regional-widget-assets/propertyBrokers/icons/bedroom-icon@2x.png'} className='icon_style_2' alt='' loading="lazy"  /></span></div></>}</div>
                        <div>{listing.bathroomCount > 0 && <><div className='d-inline-flex align-items-center ps-1 pe-2'><span className="pt-2 pe-1 fw-bold">{listing.bathroomCount}</span><span><LazyLoadImage src={process.env.PUBLIC_URL + '/regional-widget-assets/propertyBrokers/icons/bathroom-icon@2x.png'} className='icon_style_2' alt='' loading="lazy" /></span></div></>}</div>
                        <div>{listing.carParkGarageCount > 0 && <><div className='d-inline-flex align-items-center ps-1 pe-1'><span className="pt-2 pe-1 fw-bold">{listing.carParkGarageCount}</span><span><LazyLoadImage src={process.env.PUBLIC_URL + '/regional-widget-assets/propertyBrokers/icons/garage-icon@2x.png'} className='icon_style_2' alt='' loading="lazy" /></span></div></>}</div>
                    </div>
                    {/* <div >
                        <div>
                            <p className=' mb-0 Price_2'>{listing.realestateListingPrice.length > 0 && listing.realestateListingPrice.priceDisplay}</p>
                        </div>
                    </div> */}
                </div>
                <div className='agent_detail_block_2'>
                    {_getAgentDetails(listing)}
                </div>
                <div className='bg_color_2 ps-4'>
                    <div className='Findbtn1_2'>
                        <a href="/" onClick={(event) => event.preventDefault()} rel="noreferrer" className='clickme_2'>Click here</a> to explore this property
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
    const baseChildren = <div >{data.map((listing, index) => ((createCarouselItemImage(listing, index))))}</div>;
    return (<>
        <div>
                    {/* <img src='../propertyBrokers/attachments/b776ba1692528b.18568411@2x.png' className="brand-sub-logo_2 ms-0 ps-0" alt='' /> */}
                    {/* <img src={`../propertyBrokers/${listing.realestateOffices.length > 0 && listing.realestateOffices[0].primaryLogo}`} className="brand-sub-logo_2 ms-0 ps-0" alt='Property Brokers logo' /> */}
            <div className='Heading_2'>
                <div className='float-start me-auto'>
                    <LazyLoadImage src={process.env.PUBLIC_URL + '/regional-widget-assets/propertyBrokers/attachments/b776ba1692528b.18568411@2x.png'} className="brand-sub-logo_2 ms-0 ps-0" alt='' loading="lazy" />
                </div>
                <div className='float-end'>
                    <LazyLoadImage src={process.env.PUBLIC_URL + '/regional-widget-assets/propertyBrokers/attachments/649f4b80d7d82a.30630578@2x.png'} className="brand-logo_2" alt='' loading="lazy" />
                </div>
            </div>
                    {/* <img src='../propertyBrokers/attachments/649f4b80d7d82a.30630578@2x.png' className="brand-logo_2" alt='' /> */}
                    {/* <img src={`../propertyBrokers/${listing.realestateOffices.length > 0 && listing.realestateOffices[0].secoundaryLogo}`} className="brand-logo_2" alt='Property Brokers logo' /> */}
            {(data && data.length > 0) && (
                <Carousel showArrows={carouselConfig.showArrows}
                    showStatus={carouselConfig.showStatus}
                    showIndicators={carouselConfig.showIndicators}
                    infiniteLoop={carouselConfig.infiniteLoop}
                    showThumbs={carouselConfig.showThumbs}
                    useKeyboardArrows={carouselConfig.useKeyboardArrows}
                    autoPlay={carouselConfig.autoPlay}
                    stopOnHover={carouselConfig.stopOnHover}
                    swipeable={carouselConfig.swipeable}
                    dynamicHeight={carouselConfig.dynamicHeight}
                    emulateTouch={carouselConfig.emulateTouch}
                    autoFocus={carouselConfig.autoFocus}
                    interval={carouselConfig.interval}
                    transitionTime={carouselConfig.transitionTime}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                            <span onClick={onClickHandler} title="Previous Property" style={{ ...arrowStyles, left: 0, borderRadius: '0 12px 12px 0' }}>
                                <LazyLoadImage  src={process.env.PUBLIC_URL + '/regional-widget-assets/propertyBrokers/icons/navigation-left@2x.png'} className="" alt='' loading="lazy" />
                            </span>
                        )
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                            <span onClick={onClickHandler} title="Next Property" style={{ ...arrowStyles, right: -1, borderRadius: '12px 0 0 12px' }}>
                                <LazyLoadImage  src={process.env.PUBLIC_URL + '/regional-widget-assets/propertyBrokers/icons/navigation-right@2x.png'} className="" alt='' loading="lazy" />
                            </span>
                        )
                    }
                    onChange={(index, item) =>
                        index && (_trackImpressions(item.props.children.props.lsid, eventTracker, alreadyTrackedId))}
                >
                    {baseChildren.props.children}
                </Carousel>
            )}
        </div>
    </>);
}

const Redirect = (path, listingId, eventTracker) => {
    eventTracker.trackListingClick(listingId);
    window.open(path, '_blank');
}
const _initialTracking = (listingId, eventTracker, index, alreadyTrackedId) => {
    if (index === 0) {
        _trackImpressions(listingId, eventTracker, alreadyTrackedId);
    }
}


function _getAgentDetails(listing) {
    if (listing.realestateAgents.length > 0) {
        return (<>
            <div className='agent_detail_2 float-start ps-4'>
                <div className='agent_name_2 fw-bold'>
                    {listing.realestateAgents[0].firstName} <span className='mr-1'></span>
                    {listing.realestateAgents[0].lastName}
                </div>
                <div className='agent_phone_2'>{listing.realestateAgents[0].mobile ? listing.realestateAgents[0].mobile : '-'}</div>
                <div>
                    <p className='License_2'>{staticContent.agentLicense}</p>
                </div>
            </div>
            <div className='agent_image_2 float-end'>
                {/* <img src={listing.realestateAgents[0].avatarUrl && !listing.realestateAgents[0].avatarUrl.includes('avatar') ? `${CDN_URL}images/cache/event_image_thumbnail${listing.realestateAgents[0].avatarUrl}` : avatar} className="property-owner_2" alt='' /> */}
                {/* <img src={listing.realestateAgents[0].avatarUrl && !listing.realestateAgents[0].avatarUrl.includes('avatar') ? `${CDN_URL}images/cache/user_profile_profile_picture${listing.realestateAgents[0].avatarUrl}` : avatar} className="property-owner_2" alt='' /> */}
               <div className='agent_image_bg'>
                <LazyLoadImage src={listing.realestateAgents[0].avatarUrl && !listing.realestateAgents[0].avatarUrl.includes('avatar') ? `${CDN_URL}images/cache/user_profile_page_picture${listing.realestateAgents[0].avatarUrl}` : avatar} className="property_owner_2" alt='' loading="lazy" />
               </div>
                {/* <img src={listing.realestateAgents[0].avatarUrl && !listing.realestateAgents[0].avatarUrl.includes('avatar') ? `${CDN_URL}images/cache/email_message_profile_picture${listing.realestateAgents[0].avatarUrl}` : avatar} className="property-owner_2" alt='' /> */}
            </div>
        </>);
    }
}

function _getRegion(region) {
    if (region) {
        return (<>,<br />{region}</>);
    }
}

const _trackImpressions = (listingId, eventTracker, alreadyTrackedId) => {
    if (!alreadyTrackedId.includes(listingId)) {
        eventTracker.trackListingImpression(listingId);
        alreadyTrackedId.push(listingId);
    }
}
const EventTrackedCarousel2 = withEventTracker(RWTemplate2);
export { EventTrackedCarousel2, RWTemplate2 };

// const camelSentence = (str) =>{
//     return  (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function(match, chr)
//     {
//         return chr.toUpperCase();
//     });
// }