import { Carousel } from 'react-responsive-carousel';
import React, { CSSProperties, useContext } from 'react';
import { FaBed, FaBath, FaWarehouse, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { carouselConfig, env, staticContent } from '../../constants/constants';
import '../../styles/RegionalWidget.css';
import { withEventTracker, EventTrackerContext } from '@neighbourlynz/nbly-property-events';
// import useDidMount from '../RegionalWidget';
import { addWidgetUrlParams } from '../../utils/utils'

const PREFIX_URL = env.CDN_URL;
const CDN_URL = env.CDN_URL;
const avatar = `${CDN_URL}bundles/neighbourlyregistration/img/avatar.png`;


const RWTemplate1 = (props) => {
    let data = props.data;
    const eventTracker = useContext(EventTrackerContext);
    const arrowStyles: CSSProperties = {
        position: 'fixed',
        zIndex: 2,
        // top: 'calc(55% - 15px)',
        top: '200px',
        width: 30,
        height: 30,
        fontSize: '2em',
        cursor: 'pointer',
        background: 'none',
        color: '#ef4023',
        fontWeight: 100
    };
    let alreadyTrackedId = [];
    let UrlParms = {
        ps_preview:true,
        utm_source: 'stuff',
        utm_medium: 'carousel',
        utm_campaign: 'regional',
    };
    // if (error) {
    //     return (_displayError(error));
    // }
    const createCarouselItemImage = (listing, index) => (

        <React.Fragment key={index} >
            {/* <div lsid={listing.listingId} onClick={() => Redirect(listing.realestateOriginLink, listing.listingId, eventTracker)}> */}
            <div lsid={listing.listingId} onClick={() => Redirect(addWidgetUrlParams(listing.realestateOriginLink, UrlParms), listing.listingId, eventTracker)}>
                {/* <div onClick={redirect(listing.realestateOriginLink)}> */}
                <div onLoad={_initialTracking(listing.listingId, eventTracker, index, alreadyTrackedId)} className='Heading'>{listing.subject}</div>
                <div>
                    <img className="carouselImage" src={`${PREFIX_URL}images/cache/800_low_quality/realestate_listing_images/${(listing.realestateListingImages.length > 0) && listing.realestateListingImages[0].path}`} alt="" />
                </div>
                {/* <p className="legend">Legend 2</p> */}
                <div className='ImageAddress pt-1 pl-2'>{listing.realestateListingAddress.streetNumber} {listing.realestateListingAddress.street},
                    <p className="mb-0"> {listing.realestateListingAddress.suburb}</p></div>
                <div className='Icons row'>
                    <div className='col-4'>{listing.bedroomCount > 0 && <div className='LeftIcon'>{listing.bedroomCount}<FaBed className='IconColor' /></div>}</div>
                    <div className='col-4'>{listing.bathroomCount > 0 && <div className='CenterIcon'>{listing.bathroomCount}<FaBath className='IconColor' /> </div>}</div>
                    <div className='col-4'>{listing.carParkGarageCount > 0 && <div className='RightIcon'>{listing.carParkGarageCount}<FaWarehouse className='IconColor' /></div>}</div>
                </div>
                <div >
                    <div>
                        <p className=' mb-0 Price'>{listing.realestateListingPrice.length > 0 && listing.realestateListingPrice.priceDisplay}</p>
                    </div>
                </div>
                <div>
                    <div className='row Address'>
                        <div className='col-md-7 col-sm-7 col-7 Agent-details'>
                            {_getAgentDetails(listing)}
                        </div>
                        <div className='col-md-5  col-sm-5 col-5 text-center'>
                            <div className='col-md-4 offset-md-8 col-sm-12 col-12 text-center Findbtn1'>
                                {/* <a href={ addWidgetUrlParams(listing.realestateOriginLink, UrlParms)} target="_blank" rel="noreferrer" className='Findbtn'>Find out more</a> */}
                                {/* <a href="#" rel="noreferrer" className='Findbtn'>Find out more</a> */}
                                <a href="/" onClick={ (event) => event.preventDefault() } rel="noreferrer" className='Findbtn'>Find out more</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <img src={`${CDN_URL}images/cache/real_estate_office_logo_thumbnail_2x${listing.realestateOffices.length > 0 && listing.realestateOffices[0].rectangularLogo}`} className="brand-logo" alt='' />
                    </div>
                </div>
                {/* {_trackImpressions(listing.listingId, eventTracker)} */}
            </div>
        </React.Fragment>
    );
    const baseChildren = <div >{data.map((listing, index) => ((createCarouselItemImage(listing, index))))}</div>;
    return (<>
        <div>
            {/* {_displayError(error)} */}
            {(data && data.length > 0) && (
                <Carousel showArrows={carouselConfig.showArrows}
                    showStatus={carouselConfig.showStatus}
                    showIndicators={carouselConfig.showIndicators}
                    infiniteLoop={carouselConfig.infiniteLoop}
                    showThumbs={carouselConfig.showThumbs}
                    useKeyboardArrows={carouselConfig.useKeyboardArrows}
                    autoPlay={carouselConfig.autoPlay}
                    stopOnHover={carouselConfig.stopOnHover}
                    swipeable={carouselConfig.swipeable}
                    dynamicHeight={carouselConfig.dynamicHeight}
                    emulateTouch={carouselConfig.emulateTouch}
                    autoFocus={carouselConfig.autoFocus}
                    interval={carouselConfig.interval}
                    transitionTime={carouselConfig.transitionTime}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                            <span onClick={onClickHandler} title="Previous Property" style={{ ...arrowStyles, left: 10 }}>
                                <FaAngleLeft />
                            </span>
                        )
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                            <span onClick={onClickHandler} title="Next Property" style={{ ...arrowStyles, right: 10 }}>
                                <FaAngleRight />
                            </span>
                        )
                    }
                    onChange={(index, item) =>
                        index && (_trackImpressions(item.props.children.props.lsid, eventTracker, alreadyTrackedId))}
                >
                    {baseChildren.props.children}
                </Carousel>
            )}
        </div>
    </>);
}

// const UserContext = React.createContext();
// const EventTrackedCarousel = withEventTracker<Carousel>(Carousel);
// export { EventTrackedCarousel, Carousel };
// function Redirect(path, listingId) {
const Redirect = (path, listingId, eventTracker) => {
    eventTracker.trackListingClick(listingId);
    window.open(path, '_blank');
}
const _initialTracking = (listingId, eventTracker, index, alreadyTrackedId) => {
    if (index === 0) {
        _trackImpressions(listingId, eventTracker, alreadyTrackedId);
    }
}


function _getAgentDetails(listing) {
    if (listing.realestateAgents.length > 0) {
        return (<>
            <img src={listing.realestateAgents[0].avatarUrl && !listing.realestateAgents[0].avatarUrl.includes('avatar') ? `${CDN_URL}images/cache/email_message_profile_picture${listing.realestateAgents[0].avatarUrl}` : avatar} className="property-owner" alt='' />
            <div className='owner-name'>{listing.realestateAgents[0].firstName}<p className='License'>{staticContent.agentLicense}</p>
            </div>
        </>);
    }
}

const _trackImpressions = (listingId, eventTracker, alreadyTrackedId) => {
    if (!alreadyTrackedId.includes(listingId)) {
        // if (eventTracker.isVisible()) {
        eventTracker.trackListingImpression(listingId);
        // }
        alreadyTrackedId.push(listingId);
    }
}
// function _displayError(error) {
//     if (error) {
//         return (<div className="error"><h3>{error}</h3></div>);
//     }
// }
// export default withEventTracker(RWTemplate1);
const EventTrackedCarousel = withEventTracker(RWTemplate1);
export { EventTrackedCarousel, RWTemplate1 };