
import './App.css';
import RegionalWidget from './components/RegionalWidget';


function App() {  
  return (<>
    <div className="App">
      {/* <Page/> */}
      <RegionalWidget />
    </div>
  </>
  )
}

export default App;
