import React from "react";

const PBDefault = () => {
  return (
    <>
      <div className="default-wrapper">
        <div className="top-banner">
          <img alt="Property Brokers Logo"
            src={
              process.env.PUBLIC_URL +
              "/regional-widget-assets/propertyBrokers/attachments/649f4b80d7d82a.30630578.png"
            }
            width="150px"
          />
        </div>
        <div className="body-text">
          <p>
            "We work together to get you the best result because you deserve
            nothing less"
          </p>
        </div>

        <div className="bottom-footer">
          <a type="button" className="btn default-ftr-btn btn-lg text-white" target="_blank" rel="noreferrer" href="https://www.propertybrokers.co.nz/">
            Explore properties for sale
          </a>
        </div>
      </div>
    </>
  );
};

export default PBDefault;
