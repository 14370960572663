import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'


// // import reportWebVitals from './reportWebVitals';
// import {getRequest,postRequest} from './utils/apiCalls'
// import {getQueryString,getRegionFromQS,getCurrentTime,getLocalStorage,setLocalStorage,getSessionStorage,setSessionStorage} from './utils/utils'
// const req=getRequest('https://lb.amal.dev.neighbourly.co.nz/api/rest/neighbourly/realestate/listing/214504/statistics');
// console.log(req);
// // console.log(getQueryString('test'));
// console.log(getRegionFromQS());
// console.log(getCurrentTime());
// setLocalStorage('ls','test1');
// console.log('localstorage '+getLocalStorage('ls'));
// setSessionStorage('ss','test');
// console.log('sessionstorage '+getSessionStorage('ss'));


//  ReactDOM.render(<App />,document.getElementById('root'));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);



