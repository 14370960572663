export const errorMsg = {
    MSG_NO_DATA_FOUND: "No data found",
    MSG_SERVER_ERROR: "Internal server error,please try after sometime",
    MSG_PAGE_NOT_FOUND: "Page not found",
    MSG_DEFAULT_ERROR_MESSAGE: "Please try after sometime",
    MSG_SERVICE_UNAVAILABLE: "The service is currently unavailable",
    MSG_BAD_REQUEST: "A bad request has been received",
    MSG_UN_AUTHORIZED: "You are unauthorized to perform this operation!!",
    MSG_INVALID_REGION: "The region is not valid",
    MSG_INVALID_ORGANISATION: "The organisation is not valid"
    // MSG_NO_DATA_FOUND: "NO DATA FOUND",
    // MSG_SERVER_ERROR: "INTERNAL SERVER ERROR.PLEASE TRY AFTER SOMETIME",
    // MSG_PAGE_NOT_FOUND: "PAGE NOT FOUND",
    // MSG_DEFAULT_ERROR_MESSAGE: "SOMETHING WENT WRONG,PLEASE TRY AFTER SOMETIME",
    // MSG_SERVICE_UNAVAILABLE: "THE SERVICE IS CURRENTLY UNAVAILABLE",
    // MSG_BAD_REQUEST: "BAD REQUEST HAS BEEN RECEIVED",
    // MSG_UN_AUTHORIZED: "YOU ARE UNAUTHORIZED TO PERFORM THIS OPERATION",
    // MSG_INVALID_REGION: "THE REGION IS NOT VALID"
}
export const responseCode = {
    SUCCESS_CODE: 200,
    REDIRECT_CODE: 300,
    SERVER_ERROR: 500,
    NO_DATA: 204,
    PAGE_NOT_FOUND: 404,
    SERVICE_UNAVAILABLE: 503,
    BAD_REQUEST: 400,
    UN_AUTHORIZED: 401,
    NETWORK_ERROR:"ERR_NETWORK"
}

export const carouselConfig = {
    showArrows: true,
    showStatus: false,
    showIndicators: false,
    infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: true,
    autoPlay: true,
    stopOnHover: true,
    swipeable: true,
    dynamicHeight: false,
    emulateTouch: true,
    autoFocus: true,
    thumbWidth: {},
    interval: 5000,
    transitionTime: 500,
    swipeScrollTolerance: {},
    ariaLabel: 'ariaLabel',
};

export const staticContent = {
    agentLicense: 'Licensed under the REAA 2008'
}


export const env = {
    CDN_URL: process.env.REACT_APP_CDN_URL,
    IMAGE_URL: process.env.REACT_APP_IMAGE_URL,
    RW_API: process.env.REACT_APP_RW_API,
    TOKEN: process.env.REACT_APP_TOKEN,
    NEIGHBOURLY_WEB_BASE_URL: process.env.REACT_APP_NEIGHBOURLY_WEB_BASE_URL,
    GET_BEARER_TOKEN_API: process.env.REACT_APP_GET_BEARER_TOKEN_API
};

export const regions = ["northland-region", "auckland-region", "waikato-region", "bay of plenty-region", "taranaki-region", "hawkes Bay-region", "whanganui-region", "manawatu-region", "wairarapa-region", "wellington-region", "nelson-region", "marlborough-region", "christchurch-region", "timaru-region", "dunedin-region", "queenstown-region", "southland-region","bay-of-plenty-region","canterbury-excl-chch-region","canterbury-timaru-region","hawkes-bay-region","manawatu-whanganui-region","otago-dunedin-region","southland-region","taranaki-region","waikato-excl-hamilton-region","wellington-masterton-region","west-coast-region"];

export default responseCode
