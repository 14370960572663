import React, { useState, useRef, useEffect } from 'react';
import { env, responseCode, errorMsg, regions } from '../constants/constants';
import { getRequest } from '../utils/apiCalls';
import { getRegionFromQS, randomize } from '../utils/utils'
import { EventTrackedCarousel } from './RWTemplates/RWTemplate1';
import { EventTrackedCarousel2 } from './RWTemplates/RWTemplate2';
import { Medium } from '@neighbourlynz/nbly-property-events';
import  PBDefault  from './DefaultPages/PBDefault';

// const RegionalWidget = () => {
function RegionalWidget() {
    const didMount = useDidMount();
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [officeLogo, setOfficeLogo] = useState('');
    const [officeUrl, setOfficeUrl] = useState('#');
    const [template, setTemplate] = useState('');
    const [bearerToken, setBearerToken] = useState('');
  
    let param = getRegion();

      // let region='?region=taranaki';
      if ((!param || param === '' || param === 'undefined')) {
        if (didMount) {
            // console.log('mounted');
            if (!error || error !== errorMsg.MSG_INVALID_REGION) {
                setError(errorMsg.MSG_INVALID_REGION);
            }
            return (_displayError(errorCode, error, officeLogo, officeUrl));
        }

    }

    let paramKey = param.type
    let paramValue = param.data

    let args = {
        [paramKey]: paramValue
    };
  
    if (didMount) {
        let resp = getRequest(env.GET_BEARER_TOKEN_API);
        resp.then((result) => {
            if (result.data) {
                setBearerToken(result.data);
            }
        });

        // if (didMount) {
        let getData = getRequest(env.RW_API, args);
        getData.then((response) => {
            if (response.status === responseCode.SUCCESS_CODE && response.data.status_code === "") {
                if (didMount) {
                    if(param.type === 'organisation' && response.data.data.length === 0){
                        setError(errorMsg.MSG_INVALID_ORGANISATION);
                    }

                    if(param.type === 'region' && response.data.data.length === 0){
                        setError(true);
                        setOfficeLogo(true);
                    }
                    setData(randomize(response.data.data));
                    setTemplate(response.data.data.template);
                    //for testing PB
                    setTemplate('Property-Brokers');
                    // } else {
                    //     console.log('state updated');
                }
            } else {                let errCde = response.data.status_code ? response.data.status_code : response.status;
                let errMsg = response.data.status_code ? response.data.message : errorMsg.MSG_SERVICE_UNAVAILABLE;
                console.log(errCde + '-' + errMsg);
                if (didMount) {
                    setError(errMsg);
                    setErrorCode(errCde);
                    setOfficeLogo(response.data.office_logo_image);
                    setOfficeUrl(response.data.office_url);
                }
            }
        });
        getData.catch((error) => {
            if (didMount) {
                console.log(error.code + ' - ' + error.msg);
                setError(error.msg);
                setErrorCode(error.code);
            }
        });
    }
    if (error) {
        return (_displayError(errorCode, error, officeLogo, officeUrl));
    }
    if (!bearerToken) {
        return;
    }

    // let rwt = [];

    // const bearerToken="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJuZWlnaGJvdXJseSIsImF1ZCI6Im5laWdoYm91cmx5IiwiZXhwIjoxNjYwMjIxNjc3LCJpYXQiOjE2NjAyMDcyNzcsImRhdGEiOnsidXNlciI6eyJ0eXBlIjoiYXBpIiwiaWQiOjMsIm5hbWUiOiJOZWlnaGJvdXJseSBpbnRlcm5hbCIsInJvbGVzIjpbIlJPTEVfQVBJX0FMTCIsIlJPTEVfQVBJIl19fX0.6RN5ZV7Lrgxl0kXg9ZnEC2Ngd7k6gsHl_0dRYZru0cI";
    // const bearerToken=env.BEARER_TOKEN;
    // const eventsConfig = {
    //     medium: Medium.WIDGET_STUFF_REGIONAL,
    //     apiHost: env.NEIGHBOURLY_WEB_BASE_URL,
    //     bearerToken
    // };
    const eventsConfig = {
        medium: Medium.WIDGET_STUFF_REGIONAL,
        apiHost: env.NEIGHBOURLY_WEB_BASE_URL,
        bearerToken
    };

    switch (template) {
        case 'Property-Brokers':
            return <EventTrackedCarousel2 data={data} eventsConfig={eventsConfig} />

        default:
            return <EventTrackedCarousel data={data} eventsConfig={eventsConfig} />
    }

    // if (!template || template === '' || template === 'undefined') {
    //     // rwt = RWTemplate1(data);
    //     // return <RWTemplate1 data={data} eventsConfig={eventsConfig}/>;
    //     return <EventTrackedCarousel data={data} eventsConfig={eventsConfig} />;
    // }
    // return rwt;
}

const getRegion = () => {
    let data = getRegionFromQS();
    if(data.type === 'organisation'){
        return data
    }else if (data.type === 'region'){
        if (regions.includes(data.data.toLowerCase())) {
            return data
        }
        return '';
    }
}

function useDidMount() {
    const didMountRef = useRef(true);
    useEffect(() => {
        didMountRef.current = false;
    }, []);
    return didMountRef.current;
};

function _displayError(errorCode, error, officeLogo, officeUrl) {
    if (error) {
        // return (<div className="error"><h3>{error}</h3></div>);
        if (errorCode > 0) {
            return (<>
                <div className="error">
                    <div>
                        {/* <img src={`${env.CDN_URL}bundles/neighbourlyregistration/img/avatar.png`} alt='' /> */}
                        <h2><i className="bi-exclamation-octagon-fill "></i> Oops, sorry! Something went wrong.</h2>
                        <h1>{errorCode}</h1>
                        <p>{error}</p>
                    </div>
                </div>
            </>);
        } else if (officeLogo) {
            return (<>
                {/* <a href={officeUrl} target="_blank" rel="noreferrer"> */}
                    <div>
                       
                            {/* <img width="100%" src={`${env.CDN_URL}images/cache/realestate_office_logo_thumbnail_2x/attachments/` + officeLogo} alt='' /> */}
                            <PBDefault />
                       
                    </div>
                {/* </a> */}
            </>);
        } else {
            return (<>
                <div className="error">
                    <div>
                        <p>{error}</p>
                    </div>
                </div>
            </>);
        }
    }
}
export default RegionalWidget;